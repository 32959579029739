import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import '../../assets/scss/login.scss';

import Logo from '../../assets/images/logo.png';
import LoginImage from '../../assets/images/login-image.png';

const Login = () => {
    const [error, setError] = useState("");
    const [userInput, setUserInput] = useState({
        username: "",
        password: ""
    });

    const LOGIN_USER = gql`
        mutation LoginUser($input: UserLoginInput!) {
            loginUser(input: $input) {
                token
                error
                username
            }
        }
    `;

    const [loginUser, {data}] = useMutation(LOGIN_USER);

    const handleChangeInput = (e) => {
        setUserInput({ ...userInput, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        await loginUser({
            variables: {
                input: {
                    username: userInput.username.trim(),
                    password: userInput.password.trim()
                }
            }
        });
    };

    useEffect(() => {
        if (data && data.loginUser.token) {
            sessionStorage.setItem("bm_token", data.loginUser.token);
            sessionStorage.setItem("bm_username", data.loginUser.username);
            window.location.href = "/";
        }
        
        if (data && data.loginUser.error) {
            setError(data.loginUser.error);
        }
    }, [data]);

    return (
        <div className='container-fluid login'>
            <div className='row'>
                <div className='col-12 col-md-4 text-center bg-gray'>
                    <div className='mt-3'>
                        <img src={Logo} alt="brewmood" />
                        <h3 className='my-3'>Bir Barista' dan fazlası ol</h3>
                        <img className='login-image' src={LoginImage} alt="barista" />
                    </div>

                </div>
                <div className='col-12 col-md-8'>
                    <div className='login-form-container'>
                        <form className='login-form' onSubmit={handleSubmit} >
                            <div className='text-center'>
                                <h4>Giriş Yap</h4>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="exampleInputEmail1">Kullanıcı adı</label>
                                <input
                                    required
                                    minLength={3}
                                    onChange={handleChangeInput}
                                    type="text"
                                    name='username'
                                    className="form-control mt-2"
                                    id="exampleInputEmail1"
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="exampleInputPassword1">Şifre</label>
                                <input
                                    required
                                    minLength={4}
                                    onChange={handleChangeInput}
                                    type="password"
                                    name='password'
                                    className="form-control mt-2"
                                    id="exampleInputPassword1"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary mt-3 w-100">Giriş Yap</button>
                            
                            {error &&
                                <div className="alert alert-danger mt-3" role="alert">
                                    {error}
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;
