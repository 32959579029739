import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

const BranchBoards = () => {
    const [branchPoints, setBranchPoints] = useState([]);
    const [profile, setProfile] = useState({});

    const BRANCH_POINTS = gql`
        query User_branch_points {
            user_branch_points {
                exam {
                    name
                }
                point
                avg_point
                username
            }
        }
    `;

    const USER_PROFILE = gql`
        query Me {
            me {
                name
                lastname
            }
        }
    `;
    
    const { error: branchPointsError, data: branchPointsData } = useQuery(BRANCH_POINTS, {
        fetchPolicy: "no-cache"
    });

    const { error: profileError, data: profileData } = useQuery(USER_PROFILE, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (branchPointsData && branchPointsData.user_branch_points) {
            setBranchPoints(branchPointsData.user_branch_points);
        }

        if (profileData && profileData.me) {
            setProfile(profileData.me);
        }

    }, [branchPointsData, profileData]);

    if (branchPointsError) return `Error! ${branchPointsError}`;
    if (profileError) return `Error! ${profileError}`;

    return (
        <>
            <section className="breadCrumb">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 d-flex align-items-center h-100">
                            <div className="leftSide">
                                <h1 className="title">
                                    Başarı Tablosu
                                </h1>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block">
                            <div className="bgImage bgImageFaq"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="productDetailWrapper">
                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <div className='education-status table-responsive'>
                                <h5 className='m-0'>Başarı Tablosu</h5>
                                <table className="table mt-3 branch-status">
                                    <thead>
                                        <tr>
                                            <th>Sıra</th>
                                            <th>Kullanıcı</th>
                                            <th>Puan Ortalaması</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        {branchPoints.map((item, index) => {
                                            return (
                                                <tr style={{backgroundColor: (item.username === profile.name + ' ' + profile.lastname) ? '#f2f2f2' : 'white'}} key={index}>
                                                    <td>{index + 1}.</td>
                                                    <td>{item.username}</td>
                                                    <td>{item.avg_point} puan</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default BranchBoards
