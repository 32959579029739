import React from 'react';
import { Link } from 'react-router-dom';

const NextTopicButton = ({topicId, nextTopicId}) => {
    if (nextTopicId) {
        return (
            <div style={{textAlign: 'right', marginTop: 20}}>
                <Link className="btnNext" to={`/topics/${topicId}/${nextTopicId}`}>Sonraki Konuya Geç</Link>
            </div>
        );
    }
    
    return (
        <div style={{textAlign: 'right', marginTop: 20}}>
            <Link className="btnNext" to={`/topic-questions/${topicId}`}>Şimdi Kendini Test Et</Link>
        </div>
    )
}

export default NextTopicButton;
