import React from 'react';

import '../assets/scss/index.scss';
import Logo from '../assets/images/logo.png';

const ExamLayout = ({ children }) => {
    return (
        <React.Fragment>
            <div className='text-center mt-4 mt-lg-5 mb-3'>
                <img className="img-responsive" alt="Brew Mood" src={Logo} />
            </div>
            {children}
        </React.Fragment>
    );
};

export default ExamLayout;
