import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';

import '../../assets/scss/product.scss';

let timer;
let totalTime = 0;

const Answers = ({answers, questionId, onChange, correctAnswer, showCorrectAnswer}) => {
    return answers.map((item, index) => {
        return (
            <div key={index} className="form-check">
                <input
                    className="form-check-input"
                    data-question={questionId}
                    onChange={onChange}
                    value={item.id}
                    type="radio"
                    name={`question_${questionId}`}
                    id={`answer_${item.id}`}
                />
                {showCorrectAnswer ? (
                    <label
                        className="form-check-label"
                        htmlFor={`answer_${item.id}`}
                        style={{ 
                            color: parseInt(correctAnswer) === parseInt(item.id) ? "green" : "red",
                            fontWeight: parseInt(correctAnswer) === parseInt(item.id) ? "bold" : "normal"
                        }}
                    >
                        {item.name}
                    </label>
                ) : (
                    <label
                        className="form-check-label"
                        htmlFor={`answer_${item.id}`}
                    >
                        {item.name}
                    </label>
                )}
            </div>
        )
    })
}

const TopicQuestions = () => {
    let { topicId } = useParams();
    const [topic, setTopic] = useState({
        sub_topics: []
    });
    const [questions, setQuestions] = useState([]);
    const [nextTopic, setNextTopic] = useState({});
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showCompleted, setShowCompleted] = useState(false);
    const [correctAnswerCount, setCorrectAnswerCount] = useState(0);
    const [incorrectAnswerCount, setIncorrectAnswerCount] = useState(0);

    const TOPIC_QUERY = gql`
        query Topics($id: ID!) {
            topics(id: $id) {
                id
                title
                description
                duration
                image
                next_topic {
                    id
                    sub_topics {
                        id
                    }
                }
                sub_topics {
                    title
                }
            }
        }
    `;

    const QUESTIONS_QUERY = gql`
        query TopicQuestions($topic_id: Int) {
            topicQuestions(topic_id: $topic_id) {
                id
                name
                answers {
                    id
                    name
                }
                correctAnswer {
                    topic_question_answer_id
                }
            }
        }
    `;

    const UPDATE_TOPIC_STATISTIC_COMPLETED = gql`
        mutation UpdateTopicStatisticsCompleted($input: TopicStatisticsInput!) {
            updateTopicStatisticsCompleted(input: $input)
        }
    `;
    
    const { error: topicError, data: topicData } = useQuery(TOPIC_QUERY, {
        fetchPolicy: "no-cache",
        variables: {
            id: topicId
        }
    });
    
    const { error: questionsError, data: questionsData } = useQuery(QUESTIONS_QUERY, {
        fetchPolicy: "no-cache",
        variables: {
            topic_id: parseInt(topicId)
        }
    });

    const [updateTopicStatisticCompleted] = useMutation(UPDATE_TOPIC_STATISTIC_COMPLETED);

    const updateData = async (callBack) => {
        await callBack({
            variables: {
                input: {
                    topic_id: parseInt(topicId),
                    sub_topic_id: 0,
                    is_completed: 1,
                    duration: sessionStorage.getItem(`s_${topicId}`) ? parseInt(sessionStorage.getItem(`s_${topicId}`)) : 0
                }
            }
        })
    }

    const handleSubmitTest = () => {
        let selectedCount = 0;
        let correctCount = 0;
        let incorrectCount = 0;

        questions.forEach((item, index, array) => {
            selectedCount += (item.selected) ? 1 : 0;
            if (item.selected && parseInt(item.selected) === parseInt(item.correctAnswer.topic_question_answer_id)) {
                correctCount += 1;
            }
            else {
                incorrectCount += 1;
            }

            if (index === array.length - 1) {
                if (selectedCount === array.length) {
                    setShowCorrectAnswer(true);
                    setShowCompleted(true);
                    setCorrectAnswerCount(correctCount);
                    setIncorrectAnswerCount(incorrectCount);

                    let s_storage = sessionStorage.getItem(`s_${topicId}`);
                    if (s_storage) {
                        sessionStorage.setItem(`s_${topicId}`, parseInt(s_storage) + totalTime);
                    }
                    else {
                        sessionStorage.setItem(`s_${topicId}`, totalTime);
                    }

                    updateData(updateTopicStatisticCompleted);
                }
                else {
                    setShowError(true);
                    setTimeout(() => {
                        setShowError(false);
                    }, 4000);
                }
            }
        });
        window.scrollTo(0, 150);
    };

    const handleChangeAnwser = (e) => {
        let question = e.currentTarget.dataset.question;
        let answer = e.currentTarget.value;
        setQuestions(current => 
            current.map(obj => {
                if (parseInt(obj.id) === parseInt(question)) {
                    return {...obj, selected: answer};
                }
                return obj;
            })
        );
    };

    useEffect(() => {
        if (topicData && topicData.topics) {
            setTopic(topicData.topics);
            setNextTopic(topicData.topics.next_topic && topicData.topics.next_topic);
        }

    }, [topicData]);
    
    useEffect(() => {
        if (questionsData && questionsData.topicQuestions) {
            setQuestions(questionsData.topicQuestions);
        }

        totalTime = 0;
        if (timer) {
            clearInterval(timer);
        }

        timer = setInterval(() => {
            totalTime += 1;
        }, 1000);

        return () => {
            clearInterval(timer);
        }

    }, [questionsData]);

    if (topicError) return `Error! ${topicError}`;
    if (questionsError) return `Error! ${questionsError}`;

    return (
        <>
            <section className="breadCrumb">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 d-flex align-items-center h-100">
                            <div className="leftSide">
                                <h1 className="title">
                                    Konu Sonu Testi
                                </h1>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block">
                            <div className="bgImage"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="subInfoWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <h2 className="title">
                                {topic.title}
                            </h2>
                            
                            <div className="detail">
                                {topic.description}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="productDetailWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <div className="productLeftSideWrapper"> 
                                <h4>Sorular</h4>
                                {showError && (
                                    <div className="alert alert-danger" role="alert">
                                        Biraz hızlı gitmiyor muyuz. Testi tamamlamak için tüm soruları cevaplamalısın.
                                    </div>
                                )}
                                
                                {showCompleted && (
                                    <div className="alert alert-success" role="alert">
                                        Tebrikler testi tamamladın.
                                    </div>
                                )}

                                {questions.map((item, index) => {
                                    return (
                                        <div className='py-2 my-2' style={{borderBottom: '1px solid #EDEDED'}} key={index}>
                                            {showCorrectAnswer ? (
                                                <h6 style={{
                                                    color: item.selected && parseInt(item.selected) === parseInt(item.correctAnswer.topic_question_answer_id) ? 'green' : 'red',
                                                    fontWeight: 'bold',
                                                }}
                                                >
                                                    {index + 1}. {item.name}
                                                </h6>
                                            ) : (
                                                <h6>{index + 1}. {item.name}</h6>
                                            )}
                                            <Answers showCorrectAnswer={showCorrectAnswer} correctAnswer={item.correctAnswer.topic_question_answer_id} onChange={handleChangeAnwser} questionId={item.id} answers={item.answers} />
                                        </div>
                                    )
                                })}
                                
                                {showCorrectAnswer === true ? (
                                    <>
                                        <div className='text-center mt-4'>
                                            <p>
                                                <strong style={{color: 'green'}}>Doğru: {correctAnswerCount} </strong> - 
                                                <strong style={{color: 'red'}}>Yanlış: {incorrectAnswerCount} </strong>
                                            </p>
                                            Başarı oranı: <strong> %{((100 / questions.length) * correctAnswerCount).toFixed(2)}</strong>
                                        </div>
                                        <div style={{textAlign: 'right'}}>
                                            <Link className="btnNext" to={`/topics/${nextTopic.id}/${nextTopic.sub_topics[0].id}`}>Sonraki Konuya Geç</Link>
                                        </div>
                                    </>
                                    
                                ) : (
                                    <div style={{textAlign: 'right'}}>
                                        <button onClick={handleSubmitTest} className='btnNext'>TESTİ TAMAMLA</button>
                                    </div>
                                )}

                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="product productDetailed">
                                <div className="imgWrapper">
                                    {topic.image && <img src={`https://www.brewmoodakademi.com/uploads/topics/${topic.image}`} alt="konu görseli bulunamadı" />}
                                </div>
                                <div className="infoArea">
                                    <h2 className="title">
                                        Eğitim Alt Başlıkları
                                    </h2>
                                    <ul>
                                        {topic.sub_topics.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    {index + 1}) {item.title}
                                                </li>
                                            )
                                        })}
                                        <li style={{color: 'green', fontWeight: 'bold'}}>- Konu Sonu Testi</li>
                                    </ul>
                                </div>
                                <div className="bottomArea"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default TopicQuestions
