import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

const Faq = () => {
    const [questions, setQuestions] = useState([]);

    const FAQ_QUERY = gql`
        query Faq {
            faqAll {
                question
                content
            }
        }
    `;
    
    const { error: faqError, data: faqData } = useQuery(FAQ_QUERY, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (faqData && faqData.faqAll) {
            setQuestions(faqData.faqAll);
        }

    }, [faqData]);

    if (faqError) return `Error! ${faqError}`;

    return (
        <>
            <section className="breadCrumb">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 d-flex align-items-center h-100">
                            <div className="leftSide">
                                <h1 className="title">
                                    Sıkça Sorulan Sorular
                                </h1>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block">
                            <div className="bgImage bgImageFaq"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="productDetailWrapper">
                <div className="container">
                    <div className="accordion" id="accordionExample">
                        {questions.map((item, index) => {
                            return (
                                <div key={index} className="accordion-item my-3">
                                    <h2 className="accordion-header" id={`heading_${index}`}>
                                        <button className={`accordion-button ` + (index !== 0 ? 'collapsed' : '')} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${index}`} aria-expanded={index === 0 ? true : false} aria-controls={`collapse_${index}`}>
                                            {item.question}
                                        </button>
                                    </h2>
                                    <div id={`collapse_${index}`} className={`accordion-collapse collapse ` + (index === 0 ? 'show' : '')} aria-labelledby={`heading_${index}`} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            {item.content}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    )
};

export default Faq
