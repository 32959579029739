import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import Slogan from '../../assets/images/home-banner-text.png';
import Woman from '../../assets/images/home-banner-woman.png';
import HomeFooter1 from '../../assets/images/home-footer-1.png';
import HomeFooter2 from '../../assets/images/home-footer-2.png';
import HomeFooter3 from '../../assets/images/home-footer-3.png';

const Home = () => {

    const[lastActiveExam, setLastActiveExam] = useState({});

    const LAST_ACTIVE_EXAM = gql`
        query Last_active_exam {
            last_active_exam {
                id
                duration
                name
                exam_start_date
                exam_question_counts
            }
        }
    `;

    const { error: examError, data: examData } = useQuery(LAST_ACTIVE_EXAM, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (examData && examData.last_active_exam) {
            setLastActiveExam(examData.last_active_exam);
        }
    }, [examData]);

    if (examError) return `Error! ${examError}`;

    return (
        <>
            <div className='home-banner'>
                <div className='container h-100 d-flex justify-content-center align-items-center'>
                    <div className='slogan'>
                        <img src={Slogan} alt="brew-mood" />
                    </div>
                    <div className='woman'>
                        <img src={Woman} alt="brew-mood-woman" />
                    </div>
                </div>
            </div>

            <div className='home-content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-4 home-card'>
                            <div className='home-card-body'>
                                <h3>Hoş Geldin</h3>
                                <span style={{fontWeight: 'bold', fontSize: 16, color: '#0d6e47'}}>{sessionStorage.getItem('bm_username')}</span><br /><br />
                                <Link to={`/me`}>Profilim</Link><br /><br />

                                Bizi Takip Et<br />
                                <a target="_blank" rel="noreferrer" href='https://www.facebook.com/brewmoodturkiye'><i style={{fontSize: 22}} className="bi bi-facebook"></i></a> &nbsp;
                                <a target="_blank" rel="noreferrer" href='https://twitter.com/brewmoodtr'><i style={{fontSize: 22}} className="bi bi-twitter"></i></a> &nbsp;
                                <a target="_blank" rel="noreferrer" href='https://www.instagram.com/brewmoodturkiye/'><i style={{fontSize: 22}} className="bi bi-instagram"></i></a> &nbsp;
                                <a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UC1bQnjvrjstHisza0Vn3OkQ'><i style={{fontSize: 22}} className="bi bi-youtube"></i></a>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4 home-card'>
                            <div className='home-card-body'>
                                <h3>Sınav Takvimi</h3>
                                {lastActiveExam && lastActiveExam.id ? (
                                    <>
                                        <h5 className='mb-4'>{lastActiveExam.name}</h5>
                                        <p className='mb-0'><i className="bi bi-collection"></i> {lastActiveExam.exam_question_counts} Soru</p>
                                        <p className='mb-0'><i className="bi bi-collection"></i> {lastActiveExam.duration} dk</p>
                                        <p className='mb-0'><i className="bi bi-calendar-week"></i> {lastActiveExam.exam_start_date}</p>
                                        <p className='mt-3'><Link to={'/my-exams'} className='btn btn-danger'>SINAV DETAYI</Link></p>
                                    </>
                                ): (
                                    'Henüz yaklaşan bir sınav yok. Hadi yine rahatsın :)'
                                )}
                            </div>
                        </div>
                        <div className='col-12 col-lg-4 home-card'>
                            <div className='home-card-body'>
                                <h3>Geri Bildirim</h3>
                                Eğitimler ile ilgili sende bizi değerlendir.
                                Konular, videolar ve sınavlar hakkında düşündüklerini bizimle paylaş.<br /><br />

                                <center>
                                    <button style={{backgroundColor: 'black', color: 'white'}} className='btn'>Geri Bildirim Formu </button>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='home-footer'>
                <div className='container p-4'>
                    <h4 className='text-center'>Sistemimiz Nasıl Çalışıyor?</h4>
                    <div className='row mt-5 text-center'>
                        <div className='col-12 col-lg-4 home-card'>
                            <div className='home-card-body'>
                                <img src={HomeFooter1} alt="ogren" /><br /><br />
                                <b>1.Öğren</b><br />
                                Eğitim konularını ve videoları izle
                            </div>
                        </div>
                        <div className='col-12 mt-3 mt-lg-0 col-lg-4 home-card'>
                            <div className='home-card-body'>
                            <img src={HomeFooter2} alt="test-et" /><br /><br />
                                <b>2.Test Et</b><br />
                                Eğitim konularını ve videoları izle
                            </div>
                        </div>
                        <div className='col-12 mt-3 mt-lg-0 col-lg-4 home-card'>
                            <div className='home-card-body'>
                                <img src={HomeFooter3} alt="basari" /><br /><br />
                                <b>3.Başarı</b><br />
                                Eğitim konularını ve videoları izle
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Home
