import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';

import NextTopicButton from '../../components/commons/next-topic-button';

import '../../assets/scss/product.scss';

let timer;

const SubTopics = () => {

    let { topicId, subTopicId } = useParams();
    const [topic, setTopic] = useState({
        sub_topics: []
    });
    const [subTopic, setSubTopic] = useState({
        content: ''
    });
    const [nextTopicId, setNextTopicId] = useState('');
    const [nextTopicIndex, setNextTopicIndex] = useState(0);

    const TOPIC = gql`
        query Topics($id: ID!) {
            topics(id: $id) {
                id
                title
                description
                duration
                image
                sub_topics {
                    id
                    title
                    content
                    video
                }
            }
        }
    `;

    const TOPIC_STATISTIC = gql`
        query Topic_statistics($topicId: Int!) {
            topic_statistics(topic_id: $topicId) {
                sub_topic_id
                duration
                is_completed
            }
        }
    `;

    const UPDATE_TOPIC_STATISTIC_DURATION = gql`
        mutation UpdateTopicStatisticsDuration($input: TopicStatisticsInput!) {
            updateTopicStatisticsDuration(input: $input)
        }
    `;

    const UPDATE_TOPIC_STATISTIC_SUB_TOPIC = gql`
        mutation UpdateTopicStatisticsSubTopic($input: TopicStatisticsInput!) {
            updateTopicStatisticsSubTopic(input: $input)
        }
    `;

    const [updateTopicStatisticDuration] = useMutation(UPDATE_TOPIC_STATISTIC_DURATION);
    const [updateTopicStatisticSubTopic] = useMutation(UPDATE_TOPIC_STATISTIC_SUB_TOPIC);

    const { error: topicError, data: topicData } = useQuery(TOPIC, {
        fetchPolicy: "no-cache",
        variables: {
            id: parseInt(topicId)
        }
    });

    const { error: topicStatisticError, data: topicStatisticData } = useQuery(TOPIC_STATISTIC, {
        fetchPolicy: "no-cache",
        variables: {
            topicId: parseInt(topicId)
        }
    });

    useEffect(() => {
        if (topicData && topicData.topics) {
            setTopic(topicData.topics);
            let subTopicIndex = topicData.topics.sub_topics.findIndex(item => item.id === subTopicId);
            setNextTopicId(topicData.topics.sub_topics[subTopicIndex + 1] ? topicData.topics.sub_topics[subTopicIndex + 1].id : '');
            setNextTopicIndex(topicData.topics.sub_topics[subTopicIndex + 1] ? subTopicIndex + 1 : topicData.topics.sub_topics.length);
            setSubTopic(topicData.topics.sub_topics[subTopicIndex]);
            window.scrollTo(0, 150);
        }

    }, [topicId, subTopicId, topicData]);

    useEffect(() => {
        if (topicStatisticData && topicStatisticData.topic_statistics && topicStatisticData.topic_statistics.sub_topic_id && parseInt(topicStatisticData.topic_statistics.is_completed) === 0) {
            window.location.href = `/#/topics/${topicId}/${topicStatisticData.topic_statistics.sub_topic_id}`;
            sessionStorage.setItem(`s_${topicId}`, topicStatisticData.topic_statistics.duration);
        }        

    },[topicId, topicStatisticData]);

    useEffect(() => {
        const unloadCallback = (event) => {
            sessionStorage.removeItem(`s_${topicId}`);
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, [topicId]);

    useEffect(() => {
        let totalTime = 0;
        if (timer) {
            clearInterval(timer);
        }

        timer = setInterval(() => {
            totalTime += 1;
        }, 1000);

        const updateData = async (callBack) => {
            await callBack({
                variables: {
                    input: {
                        topic_id: parseInt(topicId),
                        sub_topic_id: parseInt(subTopicId),
                        is_completed: 0,
                        duration: sessionStorage.getItem(`s_${topicId}`) ? parseInt(sessionStorage.getItem(`s_${topicId}`)) : 0
                    }
                }
            })
        }

        if (subTopicId) {
            updateData(updateTopicStatisticSubTopic);
        }

        return () => {
            clearInterval(timer);
            let s_storage = sessionStorage.getItem(`s_${topicId}`);
            if (s_storage) {
                sessionStorage.setItem(`s_${topicId}`, parseInt(s_storage) + totalTime);
            }
            else {
                sessionStorage.setItem(`s_${topicId}`, totalTime);
            }

            updateData(updateTopicStatisticDuration);
        }
    }, [topicId, subTopicId, updateTopicStatisticDuration, updateTopicStatisticSubTopic]);

    if (topicError) return `Error! ${topicError}`;
    if (topicStatisticError) return `Error! ${topicStatisticError}`;

    return (
        <>
            <section className="breadCrumb">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 d-flex align-items-center h-100">
                            <div className="leftSide">
                                <h1 className="title">
                                    Eğitim
                                </h1>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block">
                            <div className="bgImage"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="subInfoWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <h2 className="title">
                                {topic.title}
                            </h2>
                            <div className="time">
                                <i className='bi bi-alarm'></i>
                                <span>&nbsp; {topic.duration}dk</span>
                            </div>
                            <div className="detail">
                                {topic.description}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="productDetailWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <div className="productLeftSideWrapper">
                                <h2 className="title">
                                    {subTopic.title}
                                </h2>
                                <div dangerouslySetInnerHTML={{ __html: subTopic.content.replace('Powered by', '').replace('<a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a>', '') }} />

                                {subTopic.video ? (
                                    <iframe src={'https://player.vimeo.com' + subTopic.video + '?title=1'}
                                        width="100%" height="400" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title={subTopic.title} ></iframe>
                                ) : ''}

                                <NextTopicButton topicId={topicId} nextTopicId={nextTopicId} />

                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="product productDetailed">
                                <div className="imgWrapper">
                                    {topic.image && <img src={`https://www.brewmoodakademi.com/uploads/topics/${topic.image}`} alt="konu görseli bulunamadı" />}
                                </div>
                                <div className="infoArea">
                                    <h2 className="title">
                                        Eğitim Alt Başlıkları
                                    </h2>
                                    <ul>
                                        {topic.sub_topics.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    {index < (nextTopicIndex) ? (
                                                        <Link className="btnNext" to={`/topics/${topicId}/${item.id}`}>{index + 1}) {item.title}</Link>
                                                    ) : (
                                                        <>{index + 1}) {item.title}</>
                                                    )}
                                                </li>
                                            )
                                        })}
                                        <li>- Konu Sonu Testi</li>
                                    </ul>
                                </div>
                                <div className="bottomArea">
                                    <p>
                                        Henüz bu eğitimi tamamlamadın. Konu başlıklarını bitirdikçe diğer konulara
                                        geçebileceksin. Eğitimlerdeki videoları izlemeyi sakın unutma. Eğitimin sonunda kısa
                                        bir test olacaksın :)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default SubTopics
