import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import reportWebVitals from './reportWebVitals';

import Layout from "./layouts/layout";
import BasicLayout from "./layouts/basicLayout";
import ExamLayout from "./layouts/examLayout";
import HomeLayout from "./layouts/homeLayout";

import Home from './pages/home';
import Topics from './pages/topics';
import SubTopics from './pages/sub-topics';
import TopicQuestions from './pages/topic-questions';
import Faq from './pages/faq';
import Profile from './pages/profile';
import BranchBoards from './pages/branch-boards';
import Exams from './pages/exams';
import ExamPage from './pages/exam-page';
import ExamDetails from './pages/exam-details';
import Login from './pages/login';

const httpLink = createHttpLink({
    uri: "https://brewmoodakademi.com/api_v1/graphql",
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = sessionStorage.getItem('bm_token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApolloProvider client={client}>
        <HashRouter>
            <Routes>
                <Route exact path="/" element={<HomeLayout><Home /></HomeLayout>} />
                <Route path="/topics" element={<Layout><Topics /></Layout>} />
                <Route path="/topics/:topicId/:subTopicId" element={<Layout><SubTopics /></Layout>} />
                <Route path="/topic-questions/:topicId" element={<Layout><TopicQuestions /></Layout>} />
                <Route path="/faq" element={<Layout><Faq /></Layout>} />
                <Route path="/me" element={<Layout><Profile /></Layout>} />
                <Route path="/branch-boards" element={<Layout><BranchBoards /></Layout>} />
                <Route path="/my-exams" element={<Layout><Exams /></Layout>} />
                <Route path="/exam/:examId" element={<ExamLayout><ExamPage /></ExamLayout>} />
                <Route path="/exam-detail/:examId" element={<Layout><ExamDetails /></Layout>} />
                <Route path="/login" element={<BasicLayout><Login /></BasicLayout>} />
            </Routes>
        </HashRouter>
    </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
