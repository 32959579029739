import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import '../../assets/scss/product.scss';

const Topics = () => {

    const [topics, setTopics] = useState([]);

    const TOPICS = gql`
        query TopicsAll {
            topicsAll {
                id
                title
                description
                duration
                image
                sub_topic_counts
                sub_topics {
                    id
                }
            }
        }
    `;

    const { data: topicsData, error: topicsError } = useQuery(TOPICS, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (topicsData && topicsData.topicsAll) {
            setTopics(topicsData.topicsAll);
        }

    }, [topicsData]);

    if (topicsError) return `Error! ${topicsError}`;

    return (
        <>
            <section className="breadCrumb">
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 d-flex align-items-center h-100">
                            <div className="leftSide">
                                <h1 className="title">
                                    Eğitim Konuları
                                </h1>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block">
                            <div className="bgImage"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="productWrapper">
                <div className="container">
                    <div className="productArea">
                        {topics.map(topic => {
                            return (
                                <div key={topic.id} className="product">
                                    <div className="imgWrapper">
                                        {topic.image && <img src={`https://www.brewmoodakademi.com/uploads/topics/${topic.image}`} alt="konu görseli bulunamadı" />}
                                    </div>
                                    <div className="infoArea">
                                        <h2 className="title">
                                            {topic.title}
                                        </h2>
                                        <div className="subInfo">
                                            {topic.description}
                                        </div>
                                        <div className="lessonArea">
                                            <div className="item">
                                                <i className='bi bi-alarm'></i>
                                                <span>&nbsp; {topic.duration}dk</span>
                                            </div>
                                            <div className="item">
                                                <i className="bi bi-folder2-open"></i>
                                                <span>&nbsp; {topic.sub_topic_counts} Alt Konu</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottomArea">
                                        {topic.sub_topics.length > 0 &&
                                            <Link className='btn' to={`/topics/${topic.id}/${topic.sub_topics[0].id}`}>Eğitime Başla</Link>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Topics;
