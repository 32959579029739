import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link} from 'react-router-dom';

import ProfileImg from '../../assets/images/profile-image.jpg';

const Profile = () => {

    const [userTopicStatistics, setUserTopicStatistics] = useState([]);
    const [userExamsAll, setUserExamsAll] = useState([]);
    const [branchPoints, setBranchPoints] = useState([]);
    const [profile, setProfile] = useState({});
    const [passwords, setPasswords] = useState({
        newPassword: '',
        newPasswordAgain: ''
    });
    const [errorMessage, setErrorMessage] = useState("Şimdi bize en az 4 haneli yeni bir şifre vermelisin.");

    const TOPIC_STATISTIC = gql`
        query User_topic_statistics {
            user_topic_statistics {
                topic {
                    title
                }
                duration
                is_completed
            }
        }
    `;

    const USER_PROFILE = gql`
        query Me {
            me {
                id
                name
                lastname
                email
                branch
            }
        }
    `;

    const EXAMS_ALL = gql`
        query User_examsAll {
            user_examsAll {
                exam {
                    id
                    name
                    exam_question_counts
                    exam_start_date
                }
                point {
                    question_point
                }
            }
        }
    `;

    const BRANCH_POINTS = gql`
        query User_branch_points {
            user_branch_points {
                exam {
                    name
                }
                point
                avg_point
                username
            }
        }
    `;

    const UPDATE_PASSWORD = gql`
        mutation UpdatePassword($input: UserPasswordInput!) {
            updatePassword(input: $input)
        }
    `;

    const { error: topicStatisticError, data: topicStatisticData } = useQuery(TOPIC_STATISTIC, {
        fetchPolicy: "no-cache"
    });
    
    const { error: examsAllError, data: examsAllData } = useQuery(EXAMS_ALL, {
        fetchPolicy: "no-cache"
    });
    
    const { error: branchPointsError, data: branchPointsData } = useQuery(BRANCH_POINTS, {
        fetchPolicy: "no-cache"
    });
    
    const { error: profileError, data: profileData } = useQuery(USER_PROFILE, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (topicStatisticData && topicStatisticData.user_topic_statistics) {
            setUserTopicStatistics(topicStatisticData.user_topic_statistics);
        }
        
        if (examsAllData && examsAllData.user_examsAll) {
            setUserExamsAll(examsAllData.user_examsAll);
        }
        
        if (branchPointsData && branchPointsData.user_branch_points) {
            setBranchPoints(branchPointsData.user_branch_points.slice(0, 3));
        }

        if (profileData && profileData.me) {
            setProfile(profileData.me);
        }

    }, [topicStatisticData, examsAllData, branchPointsData, profileData]);

    const handleLogout = () => {
        sessionStorage.clear();
        window.location.href = '/';
    };

    const handleChangePassword = (e) => {
        const { name, value } = e.target;
        
        setPasswords(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [updatePassword] = useMutation(UPDATE_PASSWORD);

    const handleSubmitPassword = () => {
        if (passwords.newPassword.length >= 4 && passwords.newPasswordAgain.length >= 4) {
            if (passwords.newPassword === passwords.newPasswordAgain) {
                setErrorMessage("Şifren güncelleniyor...");

                setTimeout(async () => {
                    await updatePassword({
                        variables: {
                            input: {
                                password: passwords.newPassword.trim()
                            }
                        }
                    });
                    setErrorMessage("güncellendi.");
                }, 3000);
            }
            else {
                setErrorMessage("Güzel denemeydi ama şifreler aynı değil.");
            }
        }
        else {
            setErrorMessage("En az 4 haneli bir şifre belirlemelisin.");
        }
    };

    if (topicStatisticError) return `Error! ${topicStatisticError}`;
    if (examsAllError) return `Error! ${examsAllError}`;
    if (branchPointsError) return `Error! ${branchPointsError}`;
    if (profileError) return `Error! ${profileError}`;

    return (
        <>
            <section className="breadCrumb">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 d-flex align-items-center h-100">
                            <div className="leftSide">
                                <h1 className="title">
                                    Profilim
                                </h1>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block">
                            <div className="bgImage bgImageFaq"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="productDetailWrapper">
                <div className="container">
                    <div className='row'>
                        <div className='col-12 col-lg-9'>
                            <div className='education-status table-responsive'>
                                <h5 className='m-0'>Eğitim Durumum</h5>
                                <table className="table mt-2 table-striped">
                                    <thead>
                                        <tr>
                                            <th>Konu</th>
                                            <th>Durumu</th>
                                            <th>Toplam Süre</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        {userTopicStatistics.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th>{item.topic.title}</th>
                                                    <td>{item.is_completed ? 'Tamamlandı' : 'Tamamlanmadı'}</td>
                                                    <td>{Math.floor(item.duration / 60)} dk</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className='education-status mt-2 table-responsive'>
                                <h5 className='m-0'>Sınavlarım</h5>
                                <table className="table mt-2 table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sınav#</th>
                                            <th>Soru Sayısı</th>
                                            <th>Puan</th>
                                            <th>Tarih</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        {userExamsAll.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <Link to={`/exam-detail/${item.exam.id}`}>
                                                            <b>{item.exam.name} #{item.exam.id}</b>
                                                        </Link>
                                                    </td>
                                                    <td>{item.exam.exam_question_counts} soru</td>
                                                    <td>{item.point.question_point} puan</td>
                                                    <td>{item.exam.exam_start_date}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className='col-12 col-lg-3'>
                            <div className='profile'>
                                <div className='profile-image'>
                                    <img className='img-fluid' src={ProfileImg} alt="profile" />
                                </div>
                                <div className='profile-informations mt-2'>
                                    <h6 className='m-0'>{profile.name} {profile.lastname}</h6>
                                    {profile.branch} Şubesi<br /><br />

                                    E-Mail: {profile.email}
                                </div>
                                <div className='profile-buttons text-center'>
                                    <button className='btn btn-password' data-bs-toggle="modal" data-bs-target="#passwordModal">Şifremi Değiştir</button><br />
                                    <button onClick={handleLogout} className='btn btn-logout'>Çıkış Yap</button>
                                </div>
                            </div>
                            <div className='branch-status table-responsive'>
                                <div className='title'>Şube Durumu</div>

                                <table className="table table-striped">
                                    <tbody className="table-group-divider">
                                        {branchPoints.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.username}</td>
                                                    <td>{item.avg_point} puan</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="passwordModal" className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Şifremi Değiştir</h5>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-info mt-3" role="alert">
                                {errorMessage}
                            </div>
                            <div className="form-group">
                                <input
                                    value={passwords.newPassword}
                                    type="password"
                                    className="form-control"
                                    name='newPassword'
                                    required
                                    minLength={4}
                                    onChange={handleChangePassword}
                                    placeholder="Yeni şifre" />
                            </div>
                            <div className="form-group mt-3">
                                <input
                                    value={passwords.newPasswordAgain}
                                    type="password"
                                    className="form-control"
                                    name='newPasswordAgain'
                                    required
                                    minLength={4}
                                    onChange={handleChangePassword}
                                    placeholder="Yeni şifre tekrar" />
                            </div>
                            <button onClick={handleSubmitPassword} type="button" className="btn btn-primary mt-3">Güncelle</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Profile
