import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row justify-content-center justify-content-md-start align-items-center">
                    <div className="col text-md-start text-center">
                        2022 BrewMood. Tüm hakları saklıdır. Bizi sosyal medyada takip etmeyi unutmayın.
                    </div>
                    <div className='col text-end'>
                        <a target="_blank" rel="noreferrer" href='https://www.facebook.com/brewmoodturkiye'><i style={{fontSize: 22}} className="bi bi-facebook"></i></a> &nbsp;
                        <a target="_blank" rel="noreferrer" href='https://twitter.com/brewmoodtr'><i style={{fontSize: 22}} className="bi bi-twitter"></i></a> &nbsp;
                        <a target="_blank" rel="noreferrer" href='https://www.instagram.com/brewmoodturkiye/'><i style={{fontSize: 22}} className="bi bi-instagram"></i></a> &nbsp;
                        <a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UC1bQnjvrjstHisza0Vn3OkQ'><i style={{fontSize: 22}} className="bi bi-youtube"></i></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
