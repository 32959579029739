import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link} from 'react-router-dom';

const Exams = () => {

    const[lastActiveExam, setLastActiveExam] = useState({});
    const [userExamsAll, setUserExamsAll] = useState([]);

    const LAST_ACTIVE_EXAM = gql`
        query Last_active_exam {
            last_active_exam {
                id
                duration
                name
                exam_start_date
                exam_question_counts
            }
        }
    `;

    const EXAMS_ALL = gql`
        query User_examsAll {
            user_examsAll {
                exam {
                    id
                    name
                    exam_question_counts
                    exam_start_date
                }
                point {
                    question_point
                }
            }
        }
    `;

    const CREATE_EXAM_QUESTIONS = gql`
        mutation CreateExamQuestions($input: ExamQuestionsInput!) {
            createExamQuestions(input: $input)
        }
    `;

    const { error: examError, data: examData } = useQuery(LAST_ACTIVE_EXAM, {
        fetchPolicy: "no-cache"
    });

    const { error: examsAllError, data: examsAllData } = useQuery(EXAMS_ALL, {
        fetchPolicy: "no-cache"
    });

    const [createExamQuestions, {data}] = useMutation(CREATE_EXAM_QUESTIONS);

    const handleStartExam = async () => {
        await createExamQuestions({
            fetchPolicy: "no-cache",
            variables: {
                input: {
                    exam_id: parseInt(lastActiveExam.id)
                }
            }
        });
    };

    useEffect(() => {
        setTimeout(() => {
            if (data) {
                window.location.href = `/#/exam/${lastActiveExam.id}`;
            }
        }, 500);
    }, [data, lastActiveExam]);

    useEffect(() => {
        if (examData && examData.last_active_exam) {
            setLastActiveExam(examData.last_active_exam);
        }

        if (examsAllData && examsAllData.user_examsAll) {
            setUserExamsAll(examsAllData.user_examsAll);
        }

    }, [examData, examsAllData]);

    if (examError) return `Error! ${examError}`;
    if (examsAllError) return `Error! ${examsAllError}`;

    return (
        <>
            <section className="breadCrumb">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 d-flex align-items-center h-100">
                            <div className="leftSide">
                                <h1 className="title">
                                    Sınavlarım
                                </h1>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block">
                            <div className="bgImage bgImageExam"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="productDetailWrapper">
                <div className="container">
                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <div className='exam-status'>
                                <h5 className='m-0'>Mevcut Sınav</h5>
                                {lastActiveExam && lastActiveExam.id && (
                                    <div className='mt-5'>
                                        <h5 className='text-center'>{lastActiveExam.name}</h5>
                                        <div className='row mt-4'>
                                            <div className='col-12 col-lg-4'><i className="bi bi-alarm"></i> {lastActiveExam.duration} dk</div>
                                            <div className='col-12 col-lg-4'><i className="bi bi-collection"></i> {lastActiveExam.exam_question_counts} Soru</div>
                                            <div className='col-12 col-lg-4'><i className="bi bi-calendar-week"></i> {lastActiveExam.exam_start_date}</div>
                                        </div>
                                        <div className='row mt-4'>
                                            <div className='col-12'>
                                                Sınava başladıktan sonra cevapladığınız sorulara geri dönemezsiniz ve soruları boş bırakamazsınız.
                                            </div>
                                        </div>

                                        <div className='row mt-4 mb-2'>
                                            <div className='col-12 text-center'>
                                                <button onClick={handleStartExam} className='btn btn-lg btn-danger'>SINAVA BAŞLA</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className='education-status mt-2 table-responsive'>
                                <h5 className='m-0'>Önceki Sınavlarım</h5>
                                <table className="table mt-2 table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sınav#</th>
                                            <th>Soru Sayısı</th>
                                            <th>Puan</th>
                                            <th>Tarih</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        {userExamsAll.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <Link to={`/exam-detail/${item.exam.id}`}>
                                                            <b>{item.exam.name} #{item.exam.id}</b>
                                                        </Link>
                                                    </td>
                                                    <td>{item.exam.exam_question_counts} soru</td>
                                                    <td>{item.point.question_point} puan</td>
                                                    <td>{item.exam.exam_start_date}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Exams
