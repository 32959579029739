import React, { useEffect, useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';

let timer;

const ExamPage = () => {

    const { examId } = useParams();
    const [questions, setQuestions] = useState([]);
    const [counter, setCounter] = useState(0);
    const [nextQuestionIndex, setNextQuestionIndex] = useState(0);
    const [exam, setExam] = useState({});
    const [errorMessage, setErrorMessage] = useState(false);
    const form = useRef();

    const QUESTIONS = gql`
        query Exam_questionsAll($exam_id: Int!) {
            exam_questionsAll(exam_id: $exam_id) {
                question {
                    id
                    name
                }
                answers {
                    id
                    name
                }
            }
        }
    `;

    const LAST_ACTIVE_EXAM = gql`
        query Last_active_exam {
            last_active_exam {
                id
                duration
                name
                exam_start_date
                exam_question_counts
            }
        }
    `;

    const CHECK_USER_EXAM = gql`
        query Check_user_exam($exam_id: Int!) {
            check_user_exam(exam_id: $exam_id) {
                is_active
            }
        }
    `;

    const CREATE_USER_EXAM_QUESTIONS = gql`
        mutation CreateUserExamQuestions($input: UserExamQuestionsInput!) {
            createUserExamQuestions(input: $input)
        }
    `;
    
    const UPDATE_USER_EXAM = gql`
        mutation UpdateUserExams($input: UserExamsInput!) {
            updateUserExams(input: $input)
        }
    `;

    const { error: userExamError, data: userExamData } = useQuery(CHECK_USER_EXAM, {
        fetchPolicy: "no-cache",
        variables: {
            exam_id: parseInt(examId)
        }
    });

    const { error: questionError, data: questionData } = useQuery(QUESTIONS, {
        fetchPolicy: "no-cache",
        variables: {
            exam_id: parseInt(examId)
        }
    });

    const { error: examError, data: examData } = useQuery(LAST_ACTIVE_EXAM, {
        fetchPolicy: "no-cache"
    });

    const [createUserExamQuestions] = useMutation(CREATE_USER_EXAM_QUESTIONS);
    const [updateUserExams] = useMutation(UPDATE_USER_EXAM);

    useEffect(() => {
        if (questionData && questionData.exam_questionsAll) {
            setQuestions(questionData.exam_questionsAll);
            setCounter()
        }

        if (examData && examData.last_active_exam) {
            setCounter(parseInt(examData.last_active_exam.duration) * 60);
            setExam(examData.last_active_exam);
        }

    }, [questionData, examData]);

    useEffect(() => {
        if (userExamData && userExamData.check_user_exam) {
            if (userExamData.check_user_exam.is_active !== 1) {
                setErrorMessage(true);
                clearInterval(timer);
            }
        }
    }, [userExamData])

    useEffect(() => {
        timer = setInterval(() => {
            setCounter(count => {
                if (count !== 0)
                    return count - 1;
                return 0;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        const updateExam = async () => {
            await updateUserExams({
                variables: {
                    input: {
                        exam_id: parseInt(examId),
                        is_active: 0
                    }
                }
            });
        }

        if (counter === 1) {
            updateExam();
            setTimeout(() => {
                clearInterval(timer);
            }, 1000);
        }
    }, [counter, updateUserExams, examId]);

    const handleNextQuestion = async () => {
        if(questions[nextQuestionIndex].selected) {
            if (nextQuestionIndex < parseInt(exam.exam_question_counts)) {
                await createUserExamQuestions({
                    fetchPolicy: "no-cache",
                    variables: {
                        input: {
                            exam_id: parseInt(examId),
                            question_id: parseInt(questions[nextQuestionIndex].question.id),
                            answer_id: parseInt(questions[nextQuestionIndex].selected)
                        }
                    }
                });

                setNextQuestionIndex(index => index + 1);
            }
        }

        if (parseInt(nextQuestionIndex) >= parseInt(exam.exam_question_counts - 1)) {
            await updateUserExams({
                variables: {
                    input: {
                        exam_id: parseInt(examId),
                        is_active: 0
                    }
                }
            });
            clearInterval(timer);
        }

        form.current.reset();
    }

    const handleChangeAnwser = (e) => {
        let question = e.currentTarget.dataset.question;
        let answer = e.currentTarget.value;
        setQuestions(current => 
            current.map(obj => {
                if (parseInt(obj.question.id) === parseInt(question)) {
                    return {...obj, selected: answer};
                }
                return obj;
            })
        );
    };

    if (questionError) return `Error! ${questionError}`;
    if (examError) return `Error! ${examError}`;
    if (userExamError) return `Error! ${userExamError}`;

    return (
        <>
            <section className="mainWrapper">
                <div className='quiz-container'>
                    {(questions.length && nextQuestionIndex < questions.length && errorMessage === false) && (
                        <div className='row'>
                            <div className='col-6'>
                                <div className='remaining-questions'>
                                    <div className='title'>Soru</div>
                                    <span>{nextQuestionIndex + 1} / {exam.exam_question_counts}</span>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='remaining-time'>
                                    <div className='title'>Kalan Süre</div>
                                    <span>{counter} saniye</span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='questions'>
                        {((questions.length && nextQuestionIndex < questions.length && errorMessage === false) && counter !== 0) ? (
                            <>
                                <div className='p-4'>
                                    <h5>{questions[nextQuestionIndex].question.name}</h5>
                                    <ul className='mt-4'>
                                        <form ref={form}>
                                            {questions[nextQuestionIndex].answers.map((item, index) => {
                                                return (
                                                    <li className='my-2' key={index}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                value={item.id}
                                                                data-question={questions[nextQuestionIndex].question.id}
                                                                onChange={handleChangeAnwser}
                                                                onClick={handleChangeAnwser}
                                                                type="radio"
                                                                name={`question_${questions[nextQuestionIndex].question.id}`}
                                                                id={`answer_${item.id}`}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`answer_${item.id}`}
                                                            >
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </form>
                                    </ul>
                                </div>
                                <button onClick={handleNextQuestion} className='btn btn-lg w-100 btn-success'>Cevapla</button>
                            </>
                        ) : (
                            <div className='text-center p-5'>
                                {errorMessage ? (
                                    <h3>Herşey tadında güzel abartma istersen.<br /><br />Bu sınavı daha önce tamamladın.</h3>
                                ): (
                                    counter !== 0 ? (
                                        <h3>
                                            Tebrikler sınavı tamamladın.<br /><br />
                                            Şimdi bi kahve yap kendine :)<br /><br />
                                            Sonra gel <Link to={"/me"}>Sınav Sonuçlarına</Link> bakalım.
                                        </h3>
                                    ): (
                                        <h3>Sınav süresi doldu.</h3>
                                    )
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
};

export default ExamPage
