import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const ExamDetails = () => {
    const { examId } = useParams();
    const [questions, setQuestions] = useState([]);

    const EXAM_DETAILS = gql`
        query User_exam_detail($id: ID!) {
            user_exam_detail(id: $id) {
                question
                question_answer
                question_correct_answer
                point
                question_point
            }
        }
    `;
    
    const { error: examError, data: examData } = useQuery(EXAM_DETAILS, {
        fetchPolicy: "no-cache",
        variables: {
            id: examId
        }
    });

    useEffect(() => {
        if (examData && examData.user_exam_detail) {
            setQuestions(examData.user_exam_detail);
        }

    }, [examData]);

    if (examError) return `Error! ${examError}`;

    return (
        <>
            <section className="breadCrumb">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 d-flex align-items-center h-100">
                            <div className="leftSide">
                                <h1 className="title">
                                    Sınav Detayı
                                </h1>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block">
                            <div className="bgImage bgImageFaq"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="productDetailWrapper">
                <div className="container">
                    <ul>
                        {questions.map((item, index) => {
                            return (
                                <li className='my-5' key={index}>
                                    <h5 style={{color: item.question_point !== 0 ? 'green' : 'red'}} >{index + 1}. {item.question}</h5><br />
                                    <p><b>Doğru Cevap:</b> {item.question_correct_answer}</p>
                                    <p>Senin Cevabın: {item.question_answer}</p>
                                    <p><b>Sorunun Puanı:</b> {item.point}</p>
                                    <p>Aldığın Puan: {item.question_point}</p>
                                </li>
                            )
                        })}
                    </ul>
                    <div style={{backgroundColor: '#CECECE', padding: 15}}>
                        <p><b>Doğru sayısı: </b> {questions.filter(item => item.question_point !== 0).length}</p>
                        <p><b>Yanlış sayısı: </b> {questions.filter(item => item.question_point === 0).length}</p>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ExamDetails
