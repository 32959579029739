import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logo.png';
import moment from 'moment';

const Header = () => {
    const [modalShow, setModalShow] = useState('none');
    const [userAgreementText, setUserAgreementText] = useState(null);

    const USER_AGREEMENTS = gql`
        query UserAgreements {
            userAgreements {
                user_id
            }
        }
    `;

    const USER_AGREEMENT_TEXT = gql`
        query UserAgreementText {
            userAgreementText {
                content
            }
        }
    `;

    const ADD_USER_AGREEMENT = gql`
        mutation AddUserAgreement($input: UserAgreementInput!) {
            addUserAgreement(input: $input)
        }
    `;

    const { error: userAgreementError, data: userAgreementData } = useQuery(USER_AGREEMENTS, {
        fetchPolicy: "no-cache"
    });
    
    const { error: userAgreementTextError, data: userAgreementTextData } = useQuery(USER_AGREEMENT_TEXT, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (!sessionStorage.getItem("bm_token")) {
            window.location.href = "/#/login";
        }
    }, []);

    useEffect(() => {
        if (userAgreementTextData && userAgreementTextData.userAgreementText) {
            setUserAgreementText(userAgreementTextData.userAgreementText.content);
        }

        if (userAgreementData && userAgreementData.userAgreements) {
            if (userAgreementData.userAgreements.length < 1) {
                setModalShow('block');
            }
        }

    }, [userAgreementTextData, userAgreementData]);

    const [addUserAgreement] = useMutation(ADD_USER_AGREEMENT);

    const handleDisagree = () => {
        setModalShow('none');
        sessionStorage.clear();
        window.location.href = "/#/login";
    };

    const handleAgree = async () => {
        await addUserAgreement({
            variables: {
                input: {
                    create_date: moment().format('YYYY-MM-DD HH:mm:ss')
                }
            }
        });
        setModalShow('none');
    };

    if (userAgreementError) return `Error! ${userAgreementError}`;
    if (userAgreementTextError) return `Error! ${userAgreementTextError}`;

    return (
        <header className="header">
            <div className="container">
                <div className="headerTop">
                    <div className="row">
                        <div className="col-lg-3 col-xl-4 col-4">
                            <a href="/">
                                <img className="img-responsive" alt="Brew Mood" src={Logo} />
                            </a>
                        </div>
                        <div
                            className="col-lg-9 col-xl-8 col-8 d-flex justify-content-end justify-content-lg-between align-items-center">
                            <nav className="w-100 d-flex justify-content-between  align-items-center navMenu" id="navMenu">
                                <ul>
                                    <li>
                                        <Link to={`/`}>Anasayfa</Link>
                                    </li>
                                    <li>
                                        <Link to={`/topics`}>Eğitimlerim</Link>
                                    </li>
                                    <li>
                                        <Link to={`/my-exams`}>Sınavlarım</Link>
                                    </li>
                                    <li>
                                        <Link to={`/faq`}>S.S.S</Link>
                                    </li>
                                    <li>
                                        <Link to={`/branch-boards`}>Başarı Tablosu</Link>
                                    </li>
                                </ul>
                                <Link className="btnProfile" to={`/me`}>Profilim</Link>
                            </nav>
                            <i className="bi bi-list menuIcon" id="menuIcon"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div id="userAgreementModal" style={{display: modalShow}} className="user-agreement-modal">
                <div className="modal-content">
                    <div style={{minHeight: 300, maxHeight: 400, overflowY: 'auto'}} dangerouslySetInnerHTML={{__html: userAgreementText}}></div>
                    <div className='d-flex justify-content-between'>
                        <button onClick={handleDisagree} className='btn btn-danger'>Kabul Etmiyorum</button>
                        <button onClick={handleAgree} className='btn btn-success'>Kabul Ediyorum</button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
