import React, { useEffect } from 'react';

import '../assets/scss/index.scss';

import Header from '../components/header';
import Footer from '../components/footer';

const Layout = ({ children }) => {

    useEffect(() => {
        const navMenu = document.getElementById("navMenu");
        const menuIcon = document.getElementById("menuIcon");

        menuIcon.addEventListener("click", function () {
            navMenu.classList.toggle("active");
            menuIcon.classList.toggle("active");
            menuIcon.classList.toggle("bi-x");
        });

    }, []);
    
    return (
        <React.Fragment>
            <Header />
            {children}
            <Footer />
        </React.Fragment>
    );
};

export default Layout;
